export class AlertNotification {
    public id: string;
    public notificationType: OrderNotificationType;
    public recipientAppUserId: string | null;
    public orderId: string;
    public headline: string;
    public subHeadline: string;
    public actionButtonLabel: string;
    public buttonLink: string;
    public dismissButtonLabel: string;
    public creationTime: string;
    public updateTime: string;
    public lastEditedBy: string;
    public seen: boolean;

    constructor(initialValues?: Partial<AlertNotification>) {
        this.id = initialValues?.id ?? "";
        this.notificationType = initialValues?.notificationType ?? OrderNotificationType.NEW_ORDER;
        this.recipientAppUserId = initialValues?.recipientAppUserId ?? null;
        this.orderId = initialValues?.orderId ?? "";
        this.headline = initialValues?.headline ?? "";
        this.subHeadline = initialValues?.subHeadline ?? "";
        this.actionButtonLabel = initialValues?.actionButtonLabel ?? "";
        this.buttonLink = initialValues?.buttonLink ?? "";
        this.dismissButtonLabel = initialValues?.dismissButtonLabel ?? "Später ansehen";
        this.creationTime = initialValues?.creationTime ?? "";
        this.updateTime = initialValues?.updateTime ?? "";
        this.lastEditedBy = initialValues?.lastEditedBy ?? "";
        this.seen = initialValues?.seen ?? false;
    }
}

export enum OrderNotificationType {
    NEW_ORDER = "newOrder",
}
