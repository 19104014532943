import { SKR } from "./SKR";

export type FiscalYear = {
    id: string;
    /**
     * Format YYYY-MM-DD
     */
    startDate: string;
    /**
     * Format YYYY-MM-DD
     */
    endDate: string;
};

export type DatevProperties = {
    consultantNumber: number | null; // (Steuer)Beraternummer
    clientNumber: number | null; // Mandantennummer
    defaultRevenueAccount: number | null; // Standard-Umsatzkonto
    rentalOrderAccountNumber: number | null; // Umsatzkonto Rentals
    defaultDebitorAccount: number | null; // Kundenkonto
    defaultCreditorAccount: number | null; // Lieferantenkonto
    useCustomerNumberForDebitor: boolean; // Kundennummer als Kundenkonto
    skr: SKR | null; // Standardkontierungsrahmen
    fiscalYears: FiscalYear[]; // Wirtschaftsjahre
};

export const DEFAULT_DATEV_PROPERTIES: DatevProperties = {
    consultantNumber: null,
    clientNumber: null,
    defaultRevenueAccount: null,
    rentalOrderAccountNumber: null,
    defaultDebitorAccount: null,
    defaultCreditorAccount: null,
    useCustomerNumberForDebitor: false,
    skr: null,
    fiscalYears: [],
};

export const DATEV_MIN_CONSULTANT_NUMBER = 1001;
export const DATEV_MAX_CONSULTANT_NUMBER = 9999999;
export const DATEV_MIN_CLIENT_NUMBER = 1;
export const DATEV_MAX_CLIENT_NUMBER = 99999;
export const DATEV_MIN_REVENUE_ACCOUNT_NUMBER = 1000;
export const DATEV_MIN_DEBITOR_ACCOUNT_NUMBER = 10000;
export const DATEV_MIN_CREDITOR_ACCOUNT_NUMBER = 10000;
