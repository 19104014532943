import { LatLngBounds } from "spherical-geometry-js";
import { Vertex } from "./Vertex";
import { FARMACT_DEFAULT_LOCATION } from "../constants/farmActDefaultLocation";

export class BoundingBox {
    public sw: Vertex;
    public ne: Vertex;

    constructor(initialSw: Vertex, initialNe: Vertex) {
        this.sw = initialSw;
        this.ne = initialNe;
    }

    public static fromShape(shape: Vertex[]) {
        const latLngBounds = shape.reduce((prev, curr) => prev.extend(curr), new LatLngBounds());
        let boundingBox = { ne: FARMACT_DEFAULT_LOCATION, sw: FARMACT_DEFAULT_LOCATION };
        if (!latLngBounds.isEmpty()) {
            boundingBox = {
                ne: {
                    lat: Math.max(...shape.map(vertex => vertex.lat)),
                    lng: Math.max(...shape.map(vertex => vertex.lng)),
                },
                sw: {
                    lat: Math.min(...shape.map(vertex => vertex.lat)),
                    lng: Math.min(...shape.map(vertex => vertex.lng)),
                },
            };
        }
        return boundingBox;
    }
}

export function getBoundingBoxCenter(boundingBox: BoundingBox) {
    return {
        lat: (boundingBox.ne.lat + boundingBox.sw.lat) / 2,
        lng: (boundingBox.ne.lng + boundingBox.sw.lng) / 2,
    };
}
