import { FirestoreDataConverter } from "firebase/firestore";

export type PartialConstructor<T> = {
    new (partial?: Partial<T>): T;
};

// FirebaseDataConverter to serialize data from and to Firestore.
// The only parameter must be a `class` with constructor, which accepts a portion of its own properties for initialization
export function getModelConverter<T>(Model: PartialConstructor<T>): FirestoreDataConverter<T> {
    return {
        toFirestore(model: T) {
            return JSON.parse(JSON.stringify(new Model({ ...model })));
        },
        fromFirestore(snapshot, options) {
            const data = snapshot.data(options);
            return new Model({ ...(data as T) });
        },
    };
}
