export class MailSettings {
    offer: ReceiptTypeMailSettings;
    bill: ReceiptTypeMailSettings;
    deliveryNote: ReceiptTypeMailSettings;
    creditNote: ReceiptTypeMailSettings;
    billReminder: ReceiptTypeMailSettings;
    cancellationBill: ReceiptTypeMailSettings;

    constructor(initialValues?: Partial<MailSettings>) {
        this.offer = initialValues?.offer ?? defaultSetting;
        this.bill = initialValues?.bill ?? defaultSetting;
        this.deliveryNote = initialValues?.deliveryNote ?? defaultSetting;
        this.creditNote = initialValues?.creditNote ?? defaultSetting;
        this.billReminder = initialValues?.billReminder ?? defaultSetting;
        this.cancellationBill = initialValues?.cancellationBill ?? defaultSetting;
    }
}

export type ReceiptTypeMailSettings = {
    subject: string;
    text: string;
    displayName: string;
    replyTo: string;
    cc: string;
    bcc: string;
};

const defaultSetting: ReceiptTypeMailSettings = {
    subject: "",
    text: "",
    displayName: "",
    replyTo: "",
    cc: "",
    bcc: "",
};
