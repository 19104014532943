import { v4 } from "uuid";
import { MachineVariantIdentifier } from "./Machine";

export class TollRecord {
    id: string;
    startDateTime: string; // iso datetime
    bookingId: string;
    distanceKm: number;
    costs: number;
    route: TollRecordRoute;
    machine: TollRecordMachine;
    order: TollRecordOrder | null;

    constructor(initialData?: Partial<TollRecord>) {
        this.id = initialData?.id ?? v4();
        this.startDateTime = initialData?.startDateTime ?? new Date().toISOString();
        this.bookingId = initialData?.bookingId ?? "";
        this.machine = initialData?.machine ?? {
            machineId: null,
            machineVehicleNumber: "",
        };
        this.route = initialData?.route ?? {
            start: "",
            end: "",
        };
        this.distanceKm = initialData?.distanceKm ?? 0;
        this.costs = initialData?.costs ?? 0;
        this.order = initialData?.order ?? null;
    }
}

type TollRecordMachine = {
    machineId: string | null;
    machineVehicleNumber: string;
};

type TollRecordRoute = {
    start: string;
    end: string;
};

export type TollRecordOrder = {
    orderId: string;
    orderRunId: string | null;
    orderCustomerId: string | null;
    orderMachineVariants: MachineVariantIdentifier[];
};
