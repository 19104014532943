import { AdminLevel } from "./adminTools/AdminLevel";
import { AppCompany } from "./AppCompany";
import { Customer } from "./Customer";
import { Employee } from "./Employee";
import { FALLBACK_LOCALE, Locale } from "./Locale";
import { Machine } from "./Machine";
import { OrderStatus, OrderStatusColors } from "./OrderStatus";
import { Role } from "./Role";

export const DEFAULT_ORDER_COLORS: OrderStatusColors = {
    [OrderStatus.DRAFT]: "#cccccc",
    [OrderStatus.ASSIGNED]: "#c8efe5",
    [OrderStatus.IN_PROGRESS]: "#34c29f",
    [OrderStatus.DONE]: "#9be29b",
    [OrderStatus.CHECKED]: "#345e9f",
    [OrderStatus.BILLED]: "#fec806",
};

export class AppUser {
    public id: string;
    public email: string;
    public username: string;
    public archived: boolean;
    public orderColors: OrderStatusColors;
    public locale: Locale;
    public unseenUpdateInfos: string[];
    public dispositionConfiguration: DispositionConfiguration;
    public lastSeenAppVersion: AppVersion | null;
    public latestDeviceInfo: DeviceInfo | null;

    constructor(initialValues?: Partial<AppUser>) {
        this.id = initialValues?.id ?? "";
        this.email = initialValues?.email ?? "";
        this.username = initialValues?.username ?? "";
        this.archived = initialValues?.archived ?? false;
        this.orderColors = { ...DEFAULT_ORDER_COLORS, ...initialValues?.orderColors };
        this.locale = initialValues?.locale ?? FALLBACK_LOCALE;
        this.unseenUpdateInfos = initialValues?.unseenUpdateInfos ?? [];
        this.dispositionConfiguration = initialValues?.dispositionConfiguration ?? {
            hiddenMachineIds: [],
            hiddenEmployeeIds: [],
        };
        this.lastSeenAppVersion = initialValues?.lastSeenAppVersion ?? null;
        this.latestDeviceInfo = initialValues?.latestDeviceInfo ?? null;
    }
}

export type CustomAppUserClaims = {
    defaultCompanyId?: AppCompany["id"] | null;
    roles?: CompanyRoles;
    customerIds?: { [companyId: AppCompany["id"]]: Customer["id"] };
    adminLevel?: AdminLevel;
    inactiveCompanyIds?: string[];
};

export type CompanyRoles = { [companyId: string]: Role };
export type CustomerRoles = CompanyRoles;

export type DispositionConfiguration = {
    hiddenMachineIds: Array<Machine["id"]>;
    hiddenEmployeeIds: Array<Employee["id"]>;
};

type AppVersion = {
    major: number;
    minor: number;
    patch: number;
};

type DeviceInfo = {
    /**
     * The name of the device. For example, "John's iPhone".
     *
     * This is only supported on iOS and Android 7.1 or above.
     *
     * On iOS 16+ this will return a generic device name without the appropriate [entitlements](https://developer.apple.com/documentation/bundleresources/entitlements/com_apple_developer_device-information_user-assigned-device-name).
     *
     * @since 1.0.0
     */
    name?: string;
    /**
     * The device model. For example, "iPhone13,4".
     *
     * @since 1.0.0
     */
    model: string;
    /**
     * The device platform (lowercase).
     *
     * @since 1.0.0
     */
    platform: "ios" | "android" | "web";
    /**
     * The operating system of the device.
     *
     * @since 1.0.0
     */
    operatingSystem: "ios" | "android" | "windows" | "mac" | "unknown";
    /**
     * The version of the device OS.
     *
     * @since 1.0.0
     */
    osVersion: string;
    /**
     * The iOS version number.
     *
     * Only available on iOS.
     *
     * Multi-part version numbers are crushed down into an integer padded to two-digits, ex: `"16.3.1"` -> `160301`
     *
     * @since 5.0.0
     */
    iOSVersion?: number;
    /**
     * The Android SDK version number.
     *
     * Only available on Android.
     *
     * @since 5.0.0
     */
    androidSDKVersion?: number;
    /**
     * The manufacturer of the device.
     *
     * @since 1.0.0
     */
    manufacturer: string;
    /**
     * Whether the app is running in a simulator/emulator.
     *
     * @since 1.0.0
     */
    isVirtual: boolean;
    /**
     * Approximate memory used by the current app, in bytes. Divide by
     * 1048576 to get the number of MBs used.
     *
     * @since 1.0.0
     */
    memUsed?: number;
    /**
     * How much free disk space is available on the normal data storage
     * path for the os, in bytes.
     *
     * On Android it returns the free disk space on the "system"
     * partition holding the core Android OS.
     * On iOS this value is not accurate.
     *
     * @deprecated Use `realDiskFree`.
     * @since 1.0.0
     */
    diskFree?: number;
    /**
     * The total size of the normal data storage path for the OS, in bytes.
     *
     * On Android it returns the disk space on the "system"
     * partition holding the core Android OS.
     *
     * @deprecated Use `realDiskTotal`.
     * @since 1.0.0
     */
    diskTotal?: number;
    /**
     * How much free disk space is available on the normal data storage, in bytes.
     *
     * @since 1.1.0
     */
    realDiskFree?: number;
    /**
     * The total size of the normal data storage path, in bytes.
     *
     * @since 1.1.0
     */
    realDiskTotal?: number;
    /**
     * The web view browser version
     *
     * @since 1.0.0
     */
    webViewVersion: string;
};
