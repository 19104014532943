import dayjs from "dayjs";
import { Activity } from "./Employee";

export class EmployeeLocation {
    public appUserId: string;
    public employeeId: string;
    public employeeName: string;
    public lat: number;
    public lng: number;
    public timestamp: string;
    public activity: Activity | null;

    constructor(initialValues?: Partial<EmployeeLocation>) {
        this.appUserId = initialValues?.appUserId ?? "";
        this.employeeId = initialValues?.employeeId ?? "";
        this.employeeName = initialValues?.employeeName ?? "";
        this.lat = initialValues?.lat ?? 0;
        this.lng = initialValues?.lng ?? 0;
        this.timestamp = initialValues?.timestamp ?? dayjs().unix().toString();
        this.activity = initialValues?.activity ?? null;
    }
}
