export enum TimeTrackingMode {
    COMPACT = "TimeTrackingMode_COMPACT",
    INDIVIDUAL = "TimeTrackingMode_INDIVIDUAL",
}

export enum ReceiptSettingsType {
    BILL = "BILL",
    DELIVERY_NOTE = "DELIVERY_NOTE",
    OFFER = "OFFER",
    CREDIT_NOTE = "CREDIT_NOTE",
}

export enum ReceiptContactType {
    FIXED = "ReceiptContactType_FIXED",
    CREATOR = "ReceiptContactType_CREATOR",
}
export type FixedReceiptContact = {
    type: ReceiptContactType.FIXED;
    contactPerson: string;
    contactPhone: string;
};
type CreatorReceiptContact = {
    type: ReceiptContactType.CREATOR;
};

export abstract class ReceiptSettings {
    public intro: string;
    public outro: string;
    public contact: FixedReceiptContact | CreatorReceiptContact;
    public prefix: string;
    public nextReceiptNumber: number;
    public showReceiptFooter: ShowReceiptElements;
    public showLogoHeader: ShowReceiptElements;
    public showAddressHeader: ShowReceiptElements;
    public freePositionsText: string;
    public salutation: boolean;
    public abstract type: ReceiptSettingsType;

    protected constructor(initialValues?: Partial<ReceiptSettings>) {
        this.intro = initialValues?.intro ?? "";
        this.outro = initialValues?.outro ?? "";
        this.prefix = initialValues?.prefix ?? "BE-";
        this.nextReceiptNumber = initialValues?.nextReceiptNumber ?? 1;
        this.showReceiptFooter = initialValues?.showReceiptFooter ?? ShowReceiptElements.ALWAYS;
        this.showLogoHeader = initialValues?.showLogoHeader ?? ShowReceiptElements.ALWAYS;
        this.contact = initialValues?.contact ?? {
            type: ReceiptContactType.FIXED,
            contactPerson: "",
            contactPhone: "",
        };
        this.showAddressHeader = initialValues?.showAddressHeader ?? ShowReceiptElements.ALWAYS;
        this.freePositionsText = initialValues?.freePositionsText ?? "Freie Positionen";
        this.salutation = initialValues?.salutation ?? false;
    }
}

export const CONTACT_VARIANT_TYPE_SELECT_DATA = [
    { label: "Fester Name", value: ReceiptContactType.FIXED },
    { label: "Beleg-Ersteller", value: ReceiptContactType.CREATOR },
];

export enum ShowReceiptElements {
    ALWAYS = "ShowReceiptElements_ALWAYS",
    NEVER = "ShowReceiptElements_NEVER",
    AlWAYS_EXCEPT_PRINT = "ShowReceiptElements_AlWAYS_EXCEPT_PRINT",
}
