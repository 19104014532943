import { v4 } from "uuid";

export class MachineMaintenanceRecord {
    id: string;
    machineId: string;
    name: string;
    timestamp: string;
    costs: number | null;
    note: string | null;
    operatingHours: number | null;

    constructor(initialValues?: Partial<MachineMaintenanceRecord>) {
        this.id = initialValues?.id ?? v4();
        this.machineId = initialValues?.machineId ?? "";
        this.name = initialValues?.name ?? "";
        this.timestamp = initialValues?.timestamp ?? new Date().toISOString();
        this.costs = initialValues?.costs ?? null;
        this.note = initialValues?.note ?? null;
        this.operatingHours = initialValues?.operatingHours ?? null;
    }
}
