export type DatevConnectionStatus = "NOT_CONNECTED" | "CONNECTED_INACTIVE" | "CONNECTED_ACTIVE" | "EXPIRED" | "ERROR";

export class DatevConnection {
    status: DatevConnectionStatus;
    /* ISO string */
    until: string | null;
    clientId: string | null;
    consultantNumber: number | null;
    clientNumber: number | null;
    operatingUnitId: string;
    user: string | null;
    errorCodes: string[];

    constructor(initialValues?: Partial<DatevConnection>) {
        this.status = initialValues?.status ?? "NOT_CONNECTED";
        this.until = initialValues?.until ?? null;
        this.clientId = initialValues?.clientId ?? null;
        this.consultantNumber = initialValues?.consultantNumber ?? null;
        this.clientNumber = initialValues?.clientNumber ?? null;
        this.operatingUnitId = initialValues?.operatingUnitId ?? "";
        this.user = initialValues?.user ?? null;
        this.errorCodes = initialValues?.errorCodes ?? [];
    }
}
