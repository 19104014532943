/**
 * @returns The whole extension, e.g. archive.tar.gz -> tar.gz
 **/
export function getFileExtension(fileName: string): string {
    const pos = fileName.indexOf(".");
    return pos === -1 ? "" : fileName.slice(pos + 1);
}

/**
 * @returns The basename without any extension, e.g. archive.tar.gz -> archive
 **/
export function getFileBasename(fileName: string): string {
    const pos = fileName.indexOf(".");
    return pos === -1 ? fileName : fileName.slice(0, pos);
}

/**
 * @returns The filename, e.g. foo/bar/baz.js -> baz.js
 **/
export function getFilename(path: string, separator: string = "/"): string {
    const pos = path.lastIndexOf(separator);
    return pos === -1 ? path : path.slice(pos + 1);
}

export function getExtensionForRecognizedMimeType(mimeType: string): string {
    switch (mimeType) {
        case "image/png":
            return "png";
        case "image/jpeg":
            return "jpg";
        case "application/pdf":
            return "pdf";
    }
    return "";
}

export function constructFilename(...parts: string[]): string {
    return parts.filter(Boolean).join(".");
}

/**
 * @returns Returns a version of the filename that is unique amoing the provided filenames,
 * e.g. foo/bar/baz.js, [foo/bar/baz.js, foo/bar/baz_1.js] -> foo/bar/baz_2.js
 **/
export function getUniqueFilename(filename: string, existingFilenames: string[]): string {
    let name = filename;

    const basename = getFileBasename(filename);
    const extension = getFileExtension(filename);

    for (let i = 1; existingFilenames.find(ef => ef === name); i++) {
        name = `${basename}_${i}${extension ? "." : ""}${extension}`;
    }

    return name;
}
