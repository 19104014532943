import { ChangeHistoryEntry } from "./ChangeHistoryEntry";
import { Receipt } from "../Receipt";

export enum ReceiptChangeCategory {
    CREATED = "CREATED",
    UPDATED = "UPDATED",
    DELETED = "DELETED",
}

type ReceiptChangeHistoryConstructorParams<T extends Receipt> = Omit<
    Partial<ReceiptChangeHistoryEntry<T>>,
    "snapshot"
> & { snapshot: T };

export class ReceiptChangeHistoryEntry<T extends Receipt> extends ChangeHistoryEntry {
    snapshot: T;
    category: ReceiptChangeCategory;

    constructor(initialData: ReceiptChangeHistoryConstructorParams<T>) {
        super(initialData);

        this.snapshot = initialData.snapshot;
        this.category = initialData.category ?? ReceiptChangeCategory.UPDATED;
    }
}
