import { Describe, boolean, enums, is, nullable, number, object, string, type } from "superstruct";
import { v4 } from "uuid";

export enum MarkerType {
    ORDER = "order",
    FIELD = "field",
    CUSTOMER = "customer",
    OTHER = "other",
    TRACKING = "tracking",
    DISABLED = "disabled",
    LABEL_ANCHOR = "label-anchor",
    LOADING_POINT = "loading-point",
    UNLOADING_POINT = "unloading-point",
    LOADING_AND_UNLOADING_POINT = "loading-and-unloading-point",
}

export class Marker {
    public id: string;
    public archived: boolean;
    public customerId: string | null;
    /**
     * Set when this Marker is attached to a field
     */
    public fieldId: string | null;
    public lat: number;
    public lng: number;
    public label: string;
    public type: MarkerType;

    constructor(initialData?: Partial<Marker>) {
        this.id = initialData?.id ?? v4();
        this.archived = initialData?.archived ?? false;
        this.customerId = initialData?.customerId ?? null;
        this.fieldId = initialData?.fieldId ?? null;
        this.lat = initialData?.lat ?? 0;
        this.lng = initialData?.lng ?? 0;
        this.label = initialData?.label ?? "";
        this.type = initialData?.type ?? (initialData?.fieldId ? MarkerType.FIELD : MarkerType.CUSTOMER);
    }
}

export const MarkerStruct = object({
    id: string(),
    archived: boolean(),
    customerId: nullable(string()),
    fieldId: nullable(string()),
    lat: number(),
    lng: number(),
    label: string(),
    type: enums(Object.values(MarkerType)),
}) satisfies Describe<Marker>;

export const IsMarkerStruct = type({
    lat: number(),
    lng: number(),
    type: string(),
});

export function isMarker(candidate: unknown): candidate is Marker {
    return is(candidate, IsMarkerStruct);
}
