import { Role } from "./Role";

export class Invitation {
    id: string;
    invitingCompanyId: string;
    invitingCompanyName: string;
    invitingUserId: string;
    invitingUserName: string;
    invitedAppUserId?: string;
    invitedUserMail?: string;
    invitedEmployeeId?: string;
    invitedCustomerId?: string;
    role: Role;

    constructor(initialValues?: Partial<Invitation>) {
        this.id = initialValues?.id ?? "";
        this.invitedAppUserId = initialValues?.invitedAppUserId;
        this.invitedUserMail = initialValues?.invitedUserMail;
        this.invitedEmployeeId = initialValues?.invitedEmployeeId;
        this.invitedCustomerId = initialValues?.invitedCustomerId;
        this.invitingUserId = initialValues?.invitingUserId ?? "";
        this.invitingUserName = initialValues?.invitingUserName ?? "";
        this.invitingCompanyId = initialValues?.invitingCompanyId ?? "";
        this.invitingCompanyName = initialValues?.invitingCompanyName ?? "";
        this.role = initialValues?.role ?? Role.EMPLOYEE;
    }
}
