import dayjs from "dayjs";
import { v4 } from "uuid";

export type VertexWithTime = {
    lat: number;
    lng: number;
    /**
     * unix timestamp (seconds since Unix Epoch)
     */
    time: number;
};

export class GeolocationTracking {
    id: string;
    public employeeId: string | null;
    runId: string;
    start: number;
    public trackingPlain: VertexWithTime[];

    constructor(initialValues?: Partial<GeolocationTracking>) {
        this.id = initialValues?.id ?? "";
        this.employeeId = initialValues?.employeeId ?? null;
        this.runId = initialValues?.runId ?? v4();
        this.start = initialValues?.start ?? initialValues?.trackingPlain?.[0]?.time ?? dayjs().unix();
        this.trackingPlain = initialValues?.trackingPlain ?? [];
    }
}

export function constructTrackingsFromChunks(locationTrackings: GeolocationTracking[]) {
    const sortedTrackings = locationTrackings
        .filter(tracking => tracking.trackingPlain?.length)
        .sort((tracking1, tracking2) => tracking1.trackingPlain[0].time - tracking2.trackingPlain[0].time);

    const constructedTrackings: GeolocationTracking[] = [];
    if (sortedTrackings.length > 0) {
        constructedTrackings.push(sortedTrackings[0]);
    }
    for (let i = 1; i < sortedTrackings.length; i++) {
        const currentTrackingChunk = sortedTrackings[i];
        if (currentTrackingChunk.runId === constructedTrackings[constructedTrackings.length - 1].runId) {
            constructedTrackings[constructedTrackings.length - 1].trackingPlain.push(
                ...currentTrackingChunk.trackingPlain
            );
        } else {
            constructedTrackings.push(currentTrackingChunk);
        }
    }
    return constructedTrackings;
}
