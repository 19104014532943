import { DocumentReference, Query, onSnapshot } from "firebase/firestore";
import { DependencyList, useEffect, useRef, useState } from "react";
import { usePageIsVisible } from "@/util/customHooks/usePageIsVisible";

export function useCollectionData<T>(
    query?: Query<T>,
    deps: DependencyList = []
): [T[], boolean, Error | null, boolean] {
    const [collection, setCollection] = useState<T[]>([]);
    const [isLoading, setIsLoading] = useState(!!query);
    const [error, setError] = useState<Error | null>(null);
    const [resultIsFromActualQuery, setResultIsFromActualQuery] = useState(false);

    const [previousQuery, setPreviousQuery] = useState<Query<T> | undefined>(undefined);
    if (query !== undefined && previousQuery === undefined) {
        setPreviousQuery(query);
        setIsLoading(true);
    } else if (query === undefined && previousQuery !== undefined) {
        setPreviousQuery(undefined);
        setIsLoading(false);
        setCollection([]);
    }

    const isPageVisible = usePageIsVisible();

    useEffect(() => {
        if (!isPageVisible) {
            return;
        }
        if (!query) {
            return;
        }

        setIsLoading(true);
        return onSnapshot(
            query,
            snapshot => {
                setCollection(snapshot.docs.map(doc => doc.data() as T));
                setIsLoading(false);
                setError(null);
                setResultIsFromActualQuery(true);
            },
            e => {
                console.error(e, query);
                setIsLoading(false);
                setError(e);
                setResultIsFromActualQuery(false);
            }
        );
        // DONT put `query` as dependencies! This will cause infinite HTTP requests to Firestore
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...deps, isPageVisible]);

    return [collection, isLoading, error, resultIsFromActualQuery];
}

export function useDocumentData<T>(
    query?: DocumentReference<T>,
    deps: DependencyList = []
): [T | undefined, boolean, Error?] {
    const [document, setDocument] = useState<T>();
    const [isLoading, setIsLoading] = useState(!!query);
    const [error, setError] = useState<Error>();

    const queryRef = useRef<DocumentReference<T> | undefined>(query);
    queryRef.current = query;

    const [previousQuery, setPreviousQuery] = useState<DocumentReference<T> | undefined>(undefined);
    if (query !== undefined && previousQuery === undefined) {
        setPreviousQuery(query);
        setIsLoading(true);
    } else if (query === undefined && previousQuery !== undefined) {
        setPreviousQuery(undefined);
        setIsLoading(false);
        setDocument(undefined);
    }

    const isPageVisible = usePageIsVisible();

    useEffect(() => {
        if (!isPageVisible) {
            return;
        }
        if (!query) {
            return;
        }

        setIsLoading(true);
        return onSnapshot(
            query,
            snapshot => {
                if (query?.id === queryRef.current?.id) {
                    setDocument(snapshot.data());
                    setError(undefined);
                    setIsLoading(false);
                }
            },
            e => {
                console.error(e, query);
                setError(e);
                setIsLoading(false);
            }
        );
        // DONT put `query` as dependencies! This will cause infinite HTTP requests to Firestore
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...deps, isPageVisible]);

    return [document, isLoading, error];
}
