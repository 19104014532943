import { Describe, number, type } from "superstruct";

export type Vertex = {
    lat: number;
    lng: number;
};

export const VertexStruct: Describe<Vertex> = type({
    lat: number(),
    lng: number(),
});
