export class CustomerTag {
    id: string;
    name: string;
    /**
     * if set to `true`, should prevent from editing / deleting
     */
    fixed: boolean;

    /**
     * set by firestore triggers
     */
    customersCount: number;

    constructor(initialValues?: Partial<CustomerTag>) {
        this.id = initialValues?.id ?? "";
        this.name = initialValues?.name ?? "";
        this.fixed = initialValues?.fixed ?? false;
        this.customersCount = initialValues?.customersCount ?? 0;
    }
}
