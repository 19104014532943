import { BillingMode } from "../BillingMode";

export type ActivityLog = {
    startDateTime: string;
    endDateTime: string | null;
    note: string;
};

export type EmployeeMonthlyUsage = {
    appUserId: string | null;
    employeeId: string;
    name: string;
    billingMode: BillingMode;
    activities: ActivityLog[];
    archived: boolean;
    netPrice: number;
};

export class MonthlyBillingData {
    public calculationDate?: string;
    public daysBilledDaily: number;
    public employees: EmployeeMonthlyUsage[];
    public employeesBilledDaily: number;
    public employeesBilledMonthly: number;
    public month: number;
    public netMonthlyBaseFee: number;
    public netPriceDaily: number;
    public netPriceMonthly: number;
    public netYearlyBaseFee: number;
    public netTotal: number;
    public newEmployees: number;
    public registeredDailyUsers: number;
    public registeredMonthlyUsers: number;
    public vat: number;
    public year: number;
    public yearlyBaseFeeDue: boolean;

    constructor(initialValues?: Partial<MonthlyBillingData>) {
        this.year = initialValues?.year ?? -1;
        this.month = initialValues?.month ?? -1;
        this.registeredMonthlyUsers = initialValues?.registeredMonthlyUsers ?? -1;
        this.registeredDailyUsers = initialValues?.registeredDailyUsers ?? -1;
        this.employees = (initialValues?.employees ?? []).map(constructEmployeeMonthlyUsage);
        this.employeesBilledMonthly = initialValues?.employeesBilledMonthly ?? 0;
        this.employeesBilledDaily = initialValues?.employeesBilledDaily ?? 0;
        this.newEmployees = initialValues?.newEmployees ?? 0;
        this.daysBilledDaily = initialValues?.daysBilledDaily ?? 0;
        this.yearlyBaseFeeDue = initialValues?.yearlyBaseFeeDue ?? false;
        this.netMonthlyBaseFee = initialValues?.netMonthlyBaseFee ?? 0;
        this.netYearlyBaseFee = initialValues?.netYearlyBaseFee ?? 0;
        this.netPriceMonthly = initialValues?.netPriceMonthly ?? 0;
        this.netPriceDaily = initialValues?.netPriceDaily ?? 0;
        this.netTotal = initialValues?.netTotal ?? 0;
        this.vat = initialValues?.vat ?? 0;
        this.calculationDate = initialValues?.calculationDate;
    }
}

function constructEmployeeMonthlyUsage(initialValues?: Partial<EmployeeMonthlyUsage>): EmployeeMonthlyUsage {
    return {
        appUserId: initialValues?.appUserId ?? null,
        employeeId: initialValues?.appUserId ?? "",
        activities: initialValues?.activities ?? [],
        billingMode: initialValues?.billingMode ?? BillingMode.NEW_EMPLOYEE,
        name: initialValues?.name ?? "Name unbekannt",
        archived: initialValues?.archived ?? false,
        netPrice: initialValues?.netPrice ?? 0,
    };
}
