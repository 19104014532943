import { Address } from "./Address";
import { OperatingUnit } from "./OperatingUnit";

export class Supplier {
    public id: string;
    public operatingUnitIds: OperatingUnit["id"][];
    public name: string;
    public creditorNumber: number | null;
    public address: Omit<Address, "name">;
    public achieved: boolean;
    constructor(initialValues?: Partial<Supplier>) {
        this.id = initialValues?.id ?? "";
        this.name = initialValues?.name ?? "";
        this.creditorNumber = initialValues?.creditorNumber ?? null;
        this.operatingUnitIds = initialValues?.operatingUnitIds ?? [];
        this.address = initialValues?.address ?? {
            street: "",
            zip: "",
            city: "",
            countryCode: "DE",
        };
        this.achieved = initialValues?.achieved ?? false;
    }
}
