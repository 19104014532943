import { Describe, object, string } from "superstruct";

export class ResourceTag {
    public id: string;
    public name: string;
    constructor(initialValues?: Partial<ResourceTag>) {
        this.id = initialValues?.id ?? "";
        this.name = initialValues?.name ?? "";
    }
}

export const ResourceTagStruct: Describe<ResourceTag> = object({
    id: string(),
    name: string(),
});

export enum UnchangeableResourceTag {
    LUBRICANTS_AND_FUELS = "LUBRICANTS_AND_FUELS",
    SEEDS_AND_FERTILIZERS = "SEEDS_AND_FERTILIZERS",
    OTHER = "OTHER",
}
