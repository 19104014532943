import { Query, onSnapshot } from "firebase/firestore";
import { useEffect, useRef, useState } from "react";
import { FIREBASE_CONSTANTS } from "@/firebase/constants";
import { getChunkedQueries } from "@/firebase/helpers";
import { deduplicateById } from "@/util/deduplicateById";

type IdentifiableModel = { id: string };

export type SnapshotGenerator<TConstraint, TModel> = (contraints: TConstraint[]) => Query<TModel>;

/**
 * This hook can be used to retrieve data that is fetched with queries like `array-contains` or `in` in a snapshot manner:
 * as soon as any of the queries delivers a new result, a new render is triggered with the updated merged data from the queries
 * @param constraints to trigger fresh data, pass a new array rather than changing the contents of the previously passed
 * @param snapshotGenerator pass a stable function here, since a new one (e.g. inline arrow function) rebuilds the complete data
 * @param chunkSize Set this chunk size to a value lower than the maximum number of constraints per query.
 * needed when using multiple "in" or "array-contains-any" clauses.
 * This is necessary because Firestore has a limit of 30 constraints per query.
 * If this limit is exceeded, the query will always return an empty result.
 */
export function useMergedQueryData<Constraint, Model extends IdentifiableModel>(
    constraints: Constraint[],
    snapshotGenerator: SnapshotGenerator<Constraint, Model> | undefined,
    chunkSize = FIREBASE_CONSTANTS.DISJUNCTIVE_CLAUSES_LIMIT
) {
    const resultsMap = useRef(new Map<string, { data: Model[]; loading: boolean }>());
    const [mergedResults, setMergedResults] = useState<Model[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (!snapshotGenerator) {
            setMergedResults([]);
            setIsLoading(false);
            return;
        }
        if (constraints.length === 0 || chunkSize > FIREBASE_CONSTANTS.DISJUNCTIVE_CLAUSES_LIMIT) {
            setMergedResults([]);
            setIsLoading(false);
            return;
        }

        const chunks = getChunkedQueries(constraints, snapshotGenerator, chunkSize);
        let unsubscribes: Array<() => void> = [];
        for (let i = 0; i < chunks.length; i++) {
            resultsMap.current.set("" + i, { data: [], loading: true });
            const query = chunks[i];

            const unsubscribe = onSnapshot(query, snapshot => {
                const data = snapshot.docs.map(doc => doc.data() as Model);
                resultsMap.current.set("" + i, { data, loading: false });
                setMergedResults(
                    deduplicateById(
                        Array.from(resultsMap.current.values())
                            .map(value => value.data)
                            .flat()
                    )
                );
                setIsLoading(
                    Array.from(resultsMap.current.values())
                        .map(value => value.loading)
                        .some(Boolean)
                );
            });
            unsubscribes.push(unsubscribe);
        }

        return () => {
            for (const unsubscribe of unsubscribes) {
                unsubscribe();
            }
            unsubscribes = [];
        };
    }, [constraints, snapshotGenerator, chunkSize]);

    return [mergedResults, isLoading] as const;
}
