import dayjs from "dayjs";

export class UpdateInfo {
    public id: string;
    public title: string;
    public teaser: string;
    public text: string;
    public published: boolean;
    public date: string;

    constructor(initialValues?: Partial<UpdateInfo>) {
        this.id = initialValues?.id ?? "";
        this.title = initialValues?.title ?? "";
        this.teaser = initialValues?.teaser ?? "";
        this.text = initialValues?.text ?? "";
        this.published = initialValues?.published ?? false;
        this.date = initialValues?.date ?? dayjs().toISOString();
    }
}
