export const firebaseConfig = {
    apiKey: "AIzaSyC4IucRn3UfkEl9O6udyqkHgTXQpua5vMA",
    authDomain: "dev-farmact.firebaseapp.com",
    databaseURL: "https://dev-farmact.firebaseio.com",
    projectId: "dev-farmact",
    storageBucket: "dev-farmact.appspot.com",
    messagingSenderId: "766338308797",
    appId: "1:766338308797:web:a639a97be229b90d36f5d4",
    measurementId: "G-XKX6BN7JNV",
};

export const environment: any = "dev";

export const bugSnagApiKey = "";

export const appVersion = {
    major: 3,
    minor: 27,
    patch: 7,
};

export const stripePublicKey =
    "pk_test_51HEUp0DMi53eMe7D9g5p2VpyUiQrXUqXXVyaCYIFsdUfyIgQOqFvxuk3XCt0v0je0GiNu3yiuz5hGaO6MXYju7qK00iGo8lPIX";

export const hotJarId = 2971490;

export const localAddress = "";
