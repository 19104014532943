import { UpdateData } from "firebase/firestore";
import { is, string, type } from "superstruct";
import { BoundingBox } from "./BoundingBox";
import Field from "./Field";
import { Vertex } from "./Vertex";
import { FARMACT_DEFAULT_LOCATION } from "../constants/farmActDefaultLocation";

export class FieldMeta {
    public id: string;
    public boundingBox: BoundingBox;
    public name: string;
    public color: string;
    public other: string;
    public crop: string;
    public cropBefore: string;
    public customerId: string;
    public archived: boolean;
    /**
     * area in hectares
     */
    public areaHa: number;
    /**
     * Track length in meters
     */
    public tracksLength: number;

    constructor(initialValues?: Partial<FieldMeta>) {
        this.id = initialValues?.id ?? "";
        this.name = initialValues?.name ?? "";
        this.color = initialValues?.color ?? Field.DEFAULT_COLOR;
        this.other = initialValues?.other ?? "";
        this.customerId = initialValues?.customerId ?? "";
        this.crop = initialValues?.crop ?? "";
        this.cropBefore = initialValues?.cropBefore ?? "";
        this.archived = initialValues?.archived ?? false;
        this.boundingBox = initialValues?.boundingBox ?? { sw: FARMACT_DEFAULT_LOCATION, ne: FARMACT_DEFAULT_LOCATION };
        this.areaHa = initialValues?.areaHa ?? 0;
        this.tracksLength = initialValues?.tracksLength ?? 0;
    }

    public static fromField(field: Field) {
        return new FieldMeta({
            ...field,
            boundingBox: BoundingBox.fromShape([...field.shape, ...field.tracks.flatMap(track => track.shape)]),
            tracksLength: field.tracks.reduce((acc, track) => acc + track.length, 0),
        });
    }
}

export type FieldUpdateData = UpdateData<Field> & { shape?: Vertex[] };

export function constructFieldMetaUpdateDataFromFieldUpdate(fieldUpdate: FieldUpdateData): UpdateData<FieldMeta> {
    const update: UpdateData<FieldMeta> = {};
    if (fieldUpdate.areaHa !== undefined) {
        update.areaHa = fieldUpdate.areaHa;
    }
    if (fieldUpdate.name !== undefined) {
        update.name = fieldUpdate.name;
    }
    if (fieldUpdate.archived !== undefined) {
        update.archived = fieldUpdate.archived;
    }
    if (fieldUpdate.shape !== undefined) {
        update.boundingBox = BoundingBox.fromShape(fieldUpdate.shape);
    }
    if (fieldUpdate.color !== undefined) {
        update.color = fieldUpdate.color;
    }
    if (fieldUpdate.crop !== undefined) {
        update.crop = fieldUpdate.crop;
    }
    if (fieldUpdate.cropBefore !== undefined) {
        update.cropBefore = fieldUpdate.cropBefore;
    }
    if (fieldUpdate.other !== undefined) {
        update.other = fieldUpdate.other;
    }
    if (fieldUpdate.customerId !== undefined) {
        update.customerId = fieldUpdate.customerId;
    }
    if (fieldUpdate.tracksLength !== undefined) {
        update.tracksLength = fieldUpdate.tracksLength;
    }
    return update;
}

export function constructFieldMetaUpdateFromFieldUpdate(fieldUpdate: Partial<Field>): Partial<FieldMeta> {
    const update: Partial<FieldMeta> = {};

    if (fieldUpdate.areaHa !== undefined) {
        update.areaHa = fieldUpdate.areaHa;
    }
    if (fieldUpdate.name !== undefined) {
        update.name = fieldUpdate.name;
    }
    if (fieldUpdate.archived !== undefined) {
        update.archived = fieldUpdate.archived;
    }
    if (fieldUpdate.shape !== undefined) {
        update.boundingBox = BoundingBox.fromShape(fieldUpdate.shape);
    }
    if (fieldUpdate.color !== undefined) {
        update.color = fieldUpdate.color;
    }
    if (fieldUpdate.crop !== undefined) {
        update.crop = fieldUpdate.crop;
    }
    if (fieldUpdate.cropBefore !== undefined) {
        update.cropBefore = fieldUpdate.cropBefore;
    }
    if (fieldUpdate.other !== undefined) {
        update.other = fieldUpdate.other;
    }
    if (fieldUpdate.customerId !== undefined) {
        update.customerId = fieldUpdate.customerId;
    }
    if (fieldUpdate.tracksLength !== undefined) {
        update.tracksLength = fieldUpdate.tracksLength;
    }

    return update;
}

const IsFieldMetaStruct = type({
    cropBefore: string(),
    boundingBox: type({}),
});

export function isFieldMeta(candidate: unknown): candidate is FieldMeta {
    return is(candidate, IsFieldMetaStruct);
}
