import { ReceiptType } from "./ReceiptType";

export const MAIL_TYPES = [
    "INTERNAL_GENERIC",
    "INTERNAL_INVITE",
    "INTERNAL_CUSTOMER_INVITE",
    "INTERNAL_EMPLOYEE_INVITE",
    "INTERNAL_COOPERATION_INVITE",
    "INTERNAL_WELCOME",
    "CUSTOMER_GENERIC",
    "CUSTOMER_RECEIPT",
    "CUSTOMER_MASCHINENRING",
] as const;
export type MailType = (typeof MAIL_TYPES)[number];

export const MAIL_SERVICE_TYPES = ["SES", "SMTP"] as const;
export type MailServiceType = (typeof MAIL_SERVICE_TYPES)[number];

export const MAIL_DELIVERY_STATUS = ["CREATED", "SENT", "DELIVERED", "ERROR"] as const;
export type MailDeliveryStatus = (typeof MAIL_DELIVERY_STATUS)[number];

/* WHY? - since this file is copied from the backend service, this way we can retreive the unix time without deps like dayjs */
export const getCurrentTimeUnixSeconds = (): number => {
    return new Date().getTime() / 1000;
};

export class ReceptionInfo {
    recipient: string;
    status: MailDeliveryStatus;
    timestamp: number;
    errorCodes: string[];

    constructor(initialValues?: Partial<ReceptionInfo>) {
        this.recipient = initialValues?.recipient ?? "";
        this.status = initialValues?.status ?? "CREATED";
        this.timestamp = initialValues?.timestamp ?? getCurrentTimeUnixSeconds();
        this.errorCodes = initialValues?.errorCodes ?? [];
    }
}

export class MailTrace {
    id: string;
    requestTimestamp: number;
    sender: string;
    recipients: string[];
    ccs: string[];
    bccs: string[];
    subject: string;
    service: MailServiceType;
    attachments: string[];
    content: string;
    receptionInfo: ReceptionInfo[];
    openCount: number;
    clickCount: number;
    properties:
        | InternalGenericMailTraceProperties
        | InternalInviteMailTraceProperties
        | InternalCustomerInviteMailTraceProperties
        | InternalEmployeeInviteMailTraceProperties
        | InternalWelcomeMailTraceProperties
        | CustomerReceiptMailTraceProperties
        | CustomerGenericMailTraceProperties;

    constructor(initialValues?: Partial<MailTrace>) {
        this.id = initialValues?.id ?? "";
        this.requestTimestamp = initialValues?.requestTimestamp ?? getCurrentTimeUnixSeconds();
        this.sender = initialValues?.sender ?? "";
        this.recipients = initialValues?.recipients ?? [];
        this.ccs = initialValues?.ccs ?? [];
        this.bccs = initialValues?.bccs ?? [];
        this.subject = initialValues?.subject ?? "";
        this.service = initialValues?.service ?? "SES";
        this.attachments = initialValues?.attachments ?? [];
        this.content = initialValues?.content ?? "";
        this.properties = initialValues?.properties ?? { mailType: "INTERNAL_GENERIC" };
        this.openCount = initialValues?.openCount ?? 0;
        this.clickCount = initialValues?.clickCount ?? 0;
        this.receptionInfo = initialValues?.receptionInfo ?? [];
    }
}

/* PROPERTIES */

export type MailTraceProperties = { mailType: MailType };

/* INTERNAL */

export type InternalMailTraceProperties = MailTraceProperties;

export type InternalGenericMailTraceProperties = InternalMailTraceProperties & { mailType: "INTERNAL_GENERIC" };
export type InternalInviteMailTraceProperties = InternalMailTraceProperties & { mailType: "INTERNAL_INVITE" };
export type InternalEmployeeInviteMailTraceProperties = InternalMailTraceProperties & {
    mailType: "INTERNAL_EMPLOYEE_INVITE";
};
export type InternalCustomerInviteMailTraceProperties = InternalMailTraceProperties & {
    mailType: "INTERNAL_CUSTOMER_INVITE";
};
export type InternalWelcomeMailTraceProperties = InternalMailTraceProperties & { mailType: "INTERNAL_WELCOME" };

/* CUSTOMER */

export type CustomerMailTraceProperties = MailTraceProperties & {
    companyId: string;
    operatingUnitId: string;
};

export type CustomerGenericMailTraceProperties = CustomerMailTraceProperties & { mailType: "CUSTOMER_GENERIC" };
export type CustomerReceiptMailTraceProperties = CustomerMailTraceProperties & {
    receiptId: string;
    receiptType: ReceiptType;
} & { mailType: "CUSTOMER_RECEIPT" };
