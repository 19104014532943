import { PhotoSize } from "@farmact/model/src/model/PhotoSize";
import { getSuffixForPhotoSize } from "@farmact/utils/src/photoSizeUtils";

export function getPhotoPath(originalPhotoPath: string, size: PhotoSize) {
    if (size === PhotoSize.ORIGINAL) {
        return originalPhotoPath;
    }
    const pathSegments = originalPhotoPath.split("/");
    const directory = pathSegments.slice(0, -1).join("/");
    const fileName = pathSegments[pathSegments.length - 1].split(".");
    if (fileName.length === 1) {
        return `${directory}/resized/${fileName[0]}_${getSuffixForPhotoSize(size)}`;
    } else {
        // Extension is hardcoded to jpeg because the resize extension is configured to convert all photos to jpeg
        return `${directory}/resized/${fileName.slice(0, -1).join(".")}_${getSuffixForPhotoSize(size)}.jpeg`;
    }
}

export function getResizedPhotosPath(originalPhotoPath: string): string {
    const pathSegments = originalPhotoPath.split("/");
    const directory = pathSegments.slice(0, -1).join("/");

    return `${directory}/resized`;
}
