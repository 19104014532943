import { Describe, boolean, is, nullable, number, object, string, type } from "superstruct";
import { FARMACT_DEFAULT_LOCATION } from "../constants/farmActDefaultLocation";

export class LoadingOrUnloadingPoint {
    public id: string;
    public archived: boolean;
    public isLoadingPoint: boolean;
    public isUnloadingPoint: boolean;
    public name: string;
    public customerId: string | null;
    public lat: number;
    public lng: number;
    public addressStreet: string;
    public addressZip: string;
    public addressCity: string;

    constructor(initialValues?: Partial<LoadingOrUnloadingPoint>) {
        this.id = initialValues?.id ?? "";
        this.archived = initialValues?.archived ?? false;
        this.isLoadingPoint = initialValues?.isLoadingPoint ?? false;
        this.isUnloadingPoint = initialValues?.isUnloadingPoint ?? false;
        this.name = initialValues?.name ?? "Be-/Entladepunkt";
        this.customerId = initialValues?.customerId ?? null;
        this.lat = initialValues?.lat ?? FARMACT_DEFAULT_LOCATION.lat;
        this.lng = initialValues?.lng ?? FARMACT_DEFAULT_LOCATION.lng;
        this.addressZip = initialValues?.addressZip ?? "";
        this.addressCity = initialValues?.addressCity ?? "";
        this.addressStreet = initialValues?.addressStreet ?? "";
    }
}

export const LoadingOrUnloadingPointStruct = object({
    id: string(),
    archived: boolean(),
    isLoadingPoint: boolean(),
    isUnloadingPoint: boolean(),
    name: string(),
    customerId: nullable(string()),
    lat: number(),
    lng: number(),
    addressStreet: string(),
    addressZip: string(),
    addressCity: string(),
}) satisfies Describe<LoadingOrUnloadingPoint>;

const IsLoadingOrUnloadingPointStruct = type({
    isLoadingPoint: boolean(),
    isUnloadingPoint: boolean(),
});

export function isLoadingOrUnloadingPoint(candidate: unknown): candidate is LoadingOrUnloadingPoint {
    return is(candidate, IsLoadingOrUnloadingPointStruct);
}
