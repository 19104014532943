export class CostAccount {
    public id: string;
    public name: string;
    public accountNumber: number;
    public archived: boolean;
    constructor(initialValues?: Partial<CostAccount>) {
        this.id = initialValues?.id ?? "";
        this.name = initialValues?.name ?? "";
        this.accountNumber = initialValues?.accountNumber ?? 0;
        this.archived = initialValues?.archived ?? false;
    }
}
