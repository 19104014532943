import { PhotoSize } from "@farmact/model/src/model/PhotoSize";

export function getSuffixForPhotoSize(size: PhotoSize) {
    switch (size) {
        case PhotoSize.XS:
            return "100x100";
        case PhotoSize.S:
            return "200x200";
        case PhotoSize.M:
            return "400x400";
        case PhotoSize.L:
            return "800x800";
        case PhotoSize.XL:
            return "1600x1600";
        case PhotoSize.XXL:
            return "4000x4000";
        case PhotoSize.ORIGINAL:
            return "";
    }
}
