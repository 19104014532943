import dayjs from "dayjs";
import { ReceiptSettings, ReceiptSettingsType } from "./ReceiptSettings";

export class OfferSettings extends ReceiptSettings {
    public type = ReceiptSettingsType.OFFER;
    constructor(initialValues?: Partial<OfferSettings>) {
        super(initialValues);
        this.prefix = initialValues?.prefix ?? `AN-${dayjs().year()}`;
    }
}
